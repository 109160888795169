'use client';
import { useQueryClient } from 'react-query';
import {
  INextError,
  LoggerInstance,
  ITrackError,
  NEXT_ERR_TRANSACTION_ID,
  NEXT_ERR_MSG,
  NEXT_LAST_TRANSACTION_ID,
} from '@vfit/shared/data-access';
import {
  IActionSlide,
  IActionType,
  IAddressDataObjectExtended,
  IAddressResponse,
  ICheckOrderErrors,
  ICMSApiError,
  ICMSError,
  ICountryObject,
  IPayMean,
  IPayMeanServiceResponse,
  IPersonalDataObject,
} from '@vfit/shared/models';
import {
  IAppSession,
  ICarouselCMS,
  ICoverageToolCongifg,
  IDXLNboProduct,
  IGlobalPromo,
  IInflaction,
  IPanicMode,
  ITechInfo,
  IWidgetType,
} from '@vfit/consumer/data-access';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import {
  AppMobile,
  checkWindow,
  getFromLocalStorageByKey,
  getHome,
  getItemCookie,
  setItemCookie,
} from '@vfit/shared/data-access';
import { getPlaceId } from '@vfit/shared/hooks';
import { cloneDeep } from 'lodash-es';
import { ICampaignResponse } from '../api/lock-in';
import { IProduct } from '../api/products/products.models';
import { ErrorCodes } from './errors';
import {
  GetCustomerResponse,
  IFindCallerResponse,
  IIdentification,
  IOwningIndividual,
} from '../api/customer';
import { IShoppingCartCost, IShoppingCartResponse } from '../api/shopping-cart';
import { CUSTOMER_TYPES } from '../api/cookie';
import { ISilentLoginResponse } from '../api/authentication';

export const ALL_EVENTS = {
  UPDATE_PRODUCT: 'updateProduct',
  UPDATE_PRODUCT_PRICE: 'updateProductPrice',
  UPDATE_PRODUCT_VOUCHER: 'updateProductVoucher',
  SILENT_LOGIN_COMPLETE: 'silentLoginComplete',
  SILENT_LOGIN_ERROR: 'silentLoginError',
};

export enum IUserType {
  LOGGED_USER = 'LOGGED_USER',
  NEXT_USER_ACTIVE = 'NEXT_USER_ACTIVE',
  NEXT_USER_INACTIVE = 'NEXT_USER_INACTIVE',
  PROSPECT_USER = 'PROSPECT_USER',
}

export const COEXISTENCE_COOKIE_KEY = 'coexzr2zr7';

export const LOGGED_USER_KEYS = ['existingLogin', 'newLogin', 'newEnriched'];

export const IN_ACTIVATION = 'act';

export const TOKEN_PARTNER_OFFER = 'v';

export const TOKEN_THIRD_PARTY_OFFER = 'token';

export const PARTNER_OFFER_COOKIE = 'ck_prtnr';

export const WIRELESS_SECURITY_TOKEN = 'WIRELESS_SECURITY_TOKEN';

export const LOCKIN_OFFER_COOKIE = 'ck_lk_sv';

export const USER_ABROAD_COOKIE = 'ck_ab_us';

export const COOKIE_TECH_APPOINTMENT = 'ck_tech_appointment';

export const COOKIE_RETRIEVE_CART = 'ck_crt_addrs';

export const COOKIE_CART = 'ck_crt';

export const PAYMENT_ERROR_TITLE = 'pymT';

export const PAYMENT_ERROR_MESSAGE = 'pymM';

export const PAYMENT_SHOW_CTC = 'pymSctc';

export const FLOW_TYPE = 'flowType';

export const BYPASS_CALL_API_SIA = 'bcas';

export const ENABLE_MIGRATION_CODE = 'enableMigrationCode';

export const SELECTED_PAYMENT = 'selectedPayment';

export const TPO_TKN = 'tpotk';

export const SELECTED_UPFRONT_PAYMENT = 'selectedUpfrontPayment';

export const IS_SELECTED_WALLET = 'isSW';

export const PAYMENT_PRESENT_CC_BANK_ACCONT = 'pymntPrsntccBnk';

export const LOCKIN_OFFER_FOUND = 'lockinOfferFound';

export const START_FROM_LOCKIN = 'startFromlockIn';

export const START_FROM_APP_LOCKIN = 'isAppLockIn';

export const IS_AUTOMATIC_RECHARGE_SELECTED = 'isAutRec';

export const PLACE_ID = 'placeId';

export const OFFER_ID = 'offerId';

export const CMS_ID = 'cmsId';

export const DXL_JWT = 'dxlJwt';

export const APP = 'isApp';

export const CONTACT_ME = 'contact_me';

export const ECMP = 'ecmp';

export const ECMP_MAIN = 'ecmpMain';

export const OTP_CHOICE = 'otpChoice';

export const CF_LEGACY = 'cfLegacy';

export const TECH_APPOINTMENT_REMINDER = 'TECH_APPOINTMENT_REMINDER';

export const FTTH_SUB_TYPE = 'FTTH_SUB_TYPE';

export const BUILDING_ID = 'Id_Building';

export const IS_APPLIED_VOUCHER = 'isApplV';

export const LOCK_IN_IS_ACCEPTED_FROM_HUB = 'lk_is_acc';

export const CMS_TOOL_START = 'cms_tool_start';

export const DXL_NBO_CAMPAIGNS = 'dxlNboCampaigns';

export const DXL_NBO_CAMPAIGN = 'dxlNboCampaign';

export const OFFER_PLACEID = 'offer_placeId';

export const OFFER_FORMATTEDADDRESS = 'offer_formattedAddress';

export const IN_TEST = 'int';

export const IN_APP = 'app';

export const START_PRODUCT_HUB = 'startProductTechOverrideHub';

export interface IAPIError {
  error: string;
  errorMessage: string;
  headers?: any;
}

export const resetKeyOnPortabilityError = [
  'checkPortInFeasibilityIIQuery',
  'checkPortInFeasibilityIIPayload',
  'modifyPhoneNumberProductQuery',
];

export const resetKeyListOnRecharge = [
  'fiscalCodeBirthPlace',
  'findCaller',
  'generateOTP',
  'verifyOTP',
  'postCustomer',
  'customerData',
  'customerDataCf',
  'updateProductCharacteristic',
  'updateProductCharacteristic_2',
  'updateProductCharacteristicLight',
  'validateDuplicateOrder',
  'paymentData',
  'payMean',
  'authorize',
  'authorizationError',
  'authorization',
  'ctxk',
  'associateCustomer',
  'individual',
  'deliveryDetailsIsEvolved',
  'billingAccount',
  'retrieveMultiLines',
  'multiPlayOfferingConfiguration',
  'checkPortInFeasibilityIIQuery',
  'checkPortInFeasibilityIIPayload',
  'modifyPhoneNumberProductQuery',
  'deliveryDetailsIsEditAddress',
  'linkContactAndCustomer',
  'postDeliveryServiceDetails',
  'deliveryAddress',
  'deliveryCreatedAddress',
  'billingAddress',
  'billingCreatedAddress',
  'deliveryDetailsIsBase',
  'billDeliveryDetails',
  'checkout',
  'searchPickUpLocations',
  'reservePhoneNumber',
  'deliveryDetailsIsAdvanced',
  'recognizedUser',
  CONTACT_ME,
  'email_cus',
  'phone_cus',
  'name_cus',
  'address_cus',
  'region_cus',
  'campaignsData',
  'campaignAccept',
  'lockInShoppingCartId',
  'lockinOfferFound',
  'checkCartInCookie',
  'retrieveCartFromCookie',
  'patchCustomer',
  'videoRecognition',
  'authorizationRecurring',
  'getCountry',
  'acceptCampaign',
  BYPASS_CALL_API_SIA,
  OTP_CHOICE,
  CF_LEGACY,
  IS_APPLIED_VOUCHER,
  'checkChannel',
  'checkOperator',
  'storeCmb',
  'pymT',
  'pymM',
  'hubPath',
  NEXT_ERR_TRANSACTION_ID,
  NEXT_LAST_TRANSACTION_ID,
  NEXT_ERR_MSG,
  START_PRODUCT_HUB,
];

export const resetKeyListOnRechargeMobile = [
  ...resetKeyListOnRecharge,
  'validatedAddress',
  'createdAddress',
  'simSelection',
  'deliveryDetailsIsUltraMobile',
  'portability',
  'deliveryDetailsIsBaseMobile',
  'deliveryDetailsIsAdvancedMobile',
  'deliveryDetailsIsEvolvedMobile',
  'deliveryDetailsIsAdvance',
  'simSelection',
  'searchPickUpStoreLocations',
  'locateStores',
  'sstps',
  'privacyProfile',
  'paymentMethod',
  'portabilityInfo',
  'customerReference',
  'modifyMobileSimDevice',
  'modifyMobileSimDeviceId',
  'oldPrivacyProfile',
  'pickupAddress',
  'pickupCreatedAddress',
  'locateStore',
  'recognition',
  'isEsim',
  'UPCAutomaticRecharge',
  'releaseResource',
  'reserve',
  ENABLE_MIGRATION_CODE,
  BYPASS_CALL_API_SIA,
  SELECTED_PAYMENT,
  PAYMENT_PRESENT_CC_BANK_ACCONT,
  OTP_CHOICE,
  CF_LEGACY,
];

export const allQueryKeyArray = [
  ...resetKeyListOnRecharge,
  ...resetKeyListOnRechargeMobile,
  ...[
    'isLockInIdServiceable',
    'tProd',
    'backupOffer',
    'validatedAddress',
    'offeringServiceability',
    'user',
    'techOffer',
    'isTechFWA',
    'isOutdoor',
    'createdAddress',
    'shoppingCart',
    'deleteShoppingCart',
    'persist',
    'loggedLockInFromHub',
    'startFromlockIn',
    'isApp',
    DXL_NBO_CAMPAIGNS,
    DXL_NBO_CAMPAIGN,
    'dxlCampaigns',
    START_FROM_APP_LOCKIN,
    LOCK_IN_IS_ACCEPTED_FROM_HUB,
    CMS_TOOL_START,
    'isAppMMOffer',
    'appSession',
    'startFromlockInId',
    'bcas',
    'notify',
    'isLP',
    'operator',
    OFFER_PLACEID,
    OFFER_FORMATTEDADDRESS,
  ],
];

// TODO DELETE the following keys once the user submitted the order and land again in the HOMEPAGE
export const resetKeyListAfterSubmit = [
  'reserveEquipment',
  'submitOrder',
  'paymentDistribution',
  'notify',
  'portability',
  'customerReference',
  'getCountry',
  'isApp',
  DXL_NBO_CAMPAIGNS,
  DXL_NBO_CAMPAIGN,
  'dxlCampaigns',
  'appSession',
  START_FROM_APP_LOCKIN,
  'isAppMMOffer',
  NEXT_ERR_TRANSACTION_ID,
  NEXT_LAST_TRANSACTION_ID,
  NEXT_ERR_MSG,
  TPO_TKN,
  SELECTED_UPFRONT_PAYMENT,
  IS_SELECTED_WALLET,
  SELECTED_PAYMENT,
];

export const CART_TYPE = {
  RECURRING: 'recurring',
  SHIPPING: 'shipping',
  PLACEHOLDER_RECURRING: '$RECURRING',
  ONE_TIME: 'oneTime',
  PLACEHOLDER_ONETIME: '$ONETIME',
  PLACEHOLDER_RECURRENCE: '$RECURRENCE',
  PLACEHOLDER_SHIPPING_PRICE: '$SHIPPING_PRICE',
  PLACEHOLDER_ORDER_ID: '$ORDER_ID',
  PLACEHOLDER_CUSTOMER_ID: '$CUSTOMER_ID',
};

export enum APP_PLACEHOLDERS {
  ACCOUNT_APP = '$ACCOUNT_APP',
  MSISDN_APP = '$MSISDN_APP',
  CHECKSUM_APP = '$CHECK_APP',
}

export enum ENRG_QSTRING {
  MSISDN = 'm',
  ACCOUNT = 'a',
  CHECKSUM = 'c',
}

export const errorMock = (
  apiCall: string,
  error?: INextError,
  errorType?: string,
  errorCode?: string,
  errorMessage?: string,
  isBlocking?: boolean,
  otherInfo?: any
): ITrackError => {
  const lastDexTransaction = localStorage?.getItem(NEXT_ERR_TRANSACTION_ID) || '';
  const lastErrorMessage = localStorage?.getItem(NEXT_ERR_MSG) || '';
  return {
    error: {
      url:
        error?.url
          ?.substring(error.url.lastIndexOf('/') + 1, error?.url?.length)
          ?.split('?')?.[0] ||
        apiCall ||
        '',
      status: error?.status || 'timeout',
      statusText: error?.statusText || 'timeout',
    },
    errorMessage: errorMessage || `Error occurred in ${apiCall} API service`,
    errorType,
    errorCode,
    ...((error?.headers?.get('dex-transaction-id') || lastDexTransaction) && {
      transaction_id: error?.headers?.get('dex-transaction-id') || lastDexTransaction || '',
    }),
    ...(error?.headers?.get('err-backenderrorcode') && {
      err_backendErrorCode: error?.headers?.get('err-backenderrorcode') || '',
    }),
    ...(error?.headers?.get('err-backenderrormessage') && {
      err_backendErrorMessage: error?.headers?.get('err-backenderrormessage') || '',
    }),
    ...(error?.headers?.get('err-backendsource') && {
      err_backendSource: error?.headers?.get('err-backendsource') || '',
    }),
    ...(error?.headers?.get('err-errorcode') && {
      err_errorCode: error?.headers?.get('err-errorcode') || '',
    }),
    ...((error?.headers?.get('err-errormessage') || lastErrorMessage) && {
      err_errorMessage: error?.headers?.get('err-errormessage') || lastErrorMessage || '',
    }),
    ...(isBlocking && { isBlocking }),
    ...(otherInfo && { otherInfo }),
  };
};

export const ALTERNATIVEOFFERIDKEY = 'alternativeOffer';
export const ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY = 'alternativeOfferShowMessage';
export const BACKUP_OFFER_KEY = 'backupOffer';

// -IMPRO
export const useGetDataFromStorage = <T>(
  key: string,
  disableLocalStorage?: boolean
): T | undefined | IAPIError => {
  if (checkWindow()) {
    // eslint-disable-next-line
    const [dataState, setDataState] = useState<T | IAPIError | undefined>(undefined);

    // eslint-disable-next-line
    const queryClient = useQueryClient();

    LoggerInstance.debug('getDataFromStorage key : ', key);

    const dataFromQuery: T | IAPIError | undefined = queryClient.getQueryData(key);

    const dataFromStorage = !disableLocalStorage && !!localStorage && localStorage.getItem(key);

    const dataFromStorageRetrieved: T | IAPIError | undefined =
      !!dataFromStorage && dataFromStorage !== 'undefined'
        ? JSON.parse(dataFromStorage as string)
        : undefined;

    // eslint-disable-next-line
    useEffect(() => {
      LoggerInstance.debug('getDataFromStorage dataFromQuery ', dataFromQuery);
      LoggerInstance.debug('GET COUNTRY VALUE - storage : ', dataFromStorage);
      if (dataFromQuery) {
        LoggerInstance.debug('QUERY WINS');
        setDataState(dataFromQuery as T | IAPIError);
      } else if (dataFromStorage) {
        LoggerInstance.debug('STORAGE WINS');

        // eslint-disable-next-line
        dataFromStorageRetrieved && setDataState(dataFromStorageRetrieved);

        LoggerInstance.debug(
          'getDataFromStorage dataFromStorageRetrieved : ',
          dataFromStorageRetrieved
        );
      }
      // eslint-disable-next-line
    }, []); // !!dataFromQuery && !!dataFromStorage
    if (!!dataFromQuery || !!dataFromStorage || !!dataState)
      return dataFromQuery || dataFromStorageRetrieved || dataState;

    // return undefined
    LoggerInstance.debug('getDataFromStorage dataState : ', dataState);

    // return  dataState || dataFromQuery || dataFromStorageRetrieved
  }
};

const getUrlExtension = (url: string): string =>
  url?.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim() || '';

export const downloadFileFunction = (url: string) => {
  const urlExt = getUrlExtension(url);
  const isPdf = urlExt === 'pdf';

  const openLink = (link: string) => {
    const isApp = AppMobile.checkIsApp();
    const appSession: IAppSession = getFromLocalStorageByKey('appSession');
    if (isApp && appSession) {
      AppMobile.openInBrowser(link);
    } else {
      window.open(link, '_blank');
    }
  };

  if (!isPdf) {
    openLink(url);
  } else {
    openLink(url);
    // fetch(url, {
    //   method: 'GET',
    // })
    //   .then((resp) => resp.blob())
    //   .then((blob) => {
    //     const urlLocal = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = urlLocal;
    //     a.download = name;
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(urlLocal);
    //   });
  }
};

/**
 *  For each element contained in the array data, it adds an N number of asterisks
 *  to the beginning or end of each item.
 *  If asterisksQt is a positive number, it adds them to the beginning,
 *  if negative it adds them to the end.
 * @param data
 * @param asterisksQt
 * @returns
 */
export const maskData = (data: string[], asterisksQt: number) => {
  /**
   *
   * @param items
   * @param mode
   * @param asteriskQt
   * @returns
   */
  const handlerDataReplace = (items: string[], mode: RegExp, asteriskQt: string) =>
    items.map((item) => item.replace(mode, asteriskQt));
  /**
   *
   * @param asteriskLength
   * @returns
   */
  const getAsterisk = (asteriskLength: number) => {
    let asteriskQt = '';
    for (let index = 0; index < asteriskLength; index += 1) {
      asteriskQt += '*';
    }
    return asteriskQt;
  };
  let fieldsWithMask: string[] = [];
  if (asterisksQt > 0) {
    const asterisk = getAsterisk(asterisksQt);
    // eslint-disable-next-line prefer-template
    const firstDigits = new RegExp('^.{' + asterisksQt + '}');
    fieldsWithMask = handlerDataReplace(data, firstDigits, asterisk);
  }
  if (asterisksQt < 0) {
    LoggerInstance.debug('asterisksQt', asterisksQt);
    const positiveNumber = asterisksQt * -1;
    const asterisk = getAsterisk(positiveNumber);
    // eslint-disable-next-line prefer-template
    const lastDigits = new RegExp('(.{' + positiveNumber + '})s*$');
    fieldsWithMask = handlerDataReplace(data, lastDigits, asterisk);
  }
  return fieldsWithMask;
};

export const getRedirectCoverage = () => {
  const home = getHome();
  return `${home}/shopping-cart`;
};

export const getFormattedPriceString = (price: string, recurrence?: string) => {
  let priceToReturn = price;
  if (recurrence) {
    if (price?.toLowerCase() === 'gratis')
      priceToReturn += `<span class='rec'> ${recurrence}</span>`;
    else if (recurrence[0] === '/') priceToReturn += `<span class='rec'>${recurrence}</span>`;
    else priceToReturn += `<span class='rec'>/${recurrence}</span>`;
  }
  return priceToReturn;
};

export const retrieveCustomerData = () => {
  const customerSaved = localStorage.getItem('customerData');
  const customerData: GetCustomerResponse[] =
    !!customerSaved && customerSaved !== 'undefined' && JSON.parse(customerSaved as string);
  return customerData;
};

export const retrieveProduct = (isReset = false): IProduct | null => {
  const customerData = retrieveCustomerData();
  const tProd_backUp = localStorage.getItem('tProd_backUp');
  if (
    isReset &&
    (!customerData || (customerData && customerData.length === 0)) &&
    tProd_backUp &&
    tProd_backUp !== 'undefined'
  ) {
    LoggerInstance.debug('retrieveProduct tProd_backUp ', tProd_backUp);
    localStorage.setItem('tProd', tProd_backUp);
    localStorage.removeItem('tProd_backUp');
    return tProd_backUp ? JSON.parse(tProd_backUp) : null;
  }
  const tProd = localStorage.getItem('tProd');
  return tProd && tProd !== 'undefined' ? JSON.parse(tProd) : null;
};

export const productSpecificError = (prod: IProduct | null) => ({
  buttonPaymentError: prod?.paymentErrorCallMeNow?.label || 'TI RICHIAMIAMO NOI',
  urlPaymentErrorCallMeNow:
    prod?.paymentErrorCallMeNow?.url ||
    'https://assistenza.vodafone.it/cmb/entrypoint/?skill=adsl_4&DESC=WEB_payment-error__NOABTEST_ORG_checkout_fixed_unlimited_2790',
  buttonGenericError: prod?.genericErrorCallMeNow?.label || 'TI RICHIAMIAMO NOI',
  urlGenericErrorCallMeNow:
    prod?.genericErrorCallMeNow?.url ||
    'https://assistenza.vodafone.it/cmb/entrypoint/?skill=adsl_4&DESC=WEB_Generic-error__NOABTEST_ORG_checkout_fixed_unlimited_2790',
});

export const retrieveShoppingCart = (): IShoppingCartResponse => {
  const shoppingCartSaved = localStorage.getItem('shoppingCart');
  return shoppingCartSaved ? JSON.parse(shoppingCartSaved as string) : null;
};

export const retrieveProductTechnology = (): string => {
  const productTechnology = localStorage.getItem('techOffer')?.replace(/["']/g, '');
  if (productTechnology) {
    return productTechnology;
  }
};

export const updateProduct = (product: IProduct) =>
  localStorage.setItem('tProd', JSON.stringify(product));

export const setPersistData = (): void => localStorage.setItem('persist', '1');

export const getPersistData = (): string => localStorage.getItem('persist');

export const deleteLoggedLockInFromHub = () => localStorage.removeItem('loggedLockInFromHub');

export const setIsStartFromLockIn = (): void => localStorage.setItem('startFromlockIn', '1');

export const getIsStartFromLockin = (): string => localStorage.getItem('startFromlockIn');

export const deleteIsStartFromLockin = () => localStorage.removeItem('startFromlockIn');

export const setStartFromLockInId = (value: string): void =>
  localStorage.setItem('startFromlockInId', value);

export const deleteIsLockInIdServiceable = () => localStorage.removeItem('isLockInIdServiceable');

export const setIsLockInIdServiceable = (): void =>
  localStorage.setItem('isLockInIdServiceable', '1');

export const isLockInIdServiceable = (): boolean =>
  localStorage.getItem('isLockInIdServiceable') === '1';

export const deleteStartFromLockinId = () => localStorage.removeItem('startFromlockInId');

export const isPersistData = (): boolean => {
  const persistData = getPersistData();
  const queryString = window?.location?.search;
  return (
    queryString?.includes(ErrorCodes.MODE_BACK_SIA) ||
    queryString?.includes(ErrorCodes.MODE_BACK_SIA_INSTANT) ||
    queryString?.includes(ErrorCodes.MODE_DONE_SIA_INSTANT) ||
    queryString.includes(ErrorCodes.MODE_BACK_SIA_RECURRENT) ||
    queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT) ||
    queryString.includes(ErrorCodes.URL_FORWARD) ||
    !!persistData
  );
};

export const isErrorOnPayment = (): boolean => {
  const queryString = window?.location?.search;
  return queryString?.includes(ErrorCodes.ERROR_ON_PAYMENT);
};

export const isInResetUser = (): boolean => {
  const queryString = window?.location?.search;
  return queryString?.includes(ErrorCodes.MODE_RESET_USER);
};

export const getSelectedOfferIds = () => {
  const backupOfferKey = getFromLocalStorageByKey(BACKUP_OFFER_KEY);
  return {
    cmsId: backupOfferKey?.cmsId,
    offerId: backupOfferKey?.offeringId,
  };
};

export const getOneTimeAmountForUpfront = (includeShipping: boolean): string | 0 => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const { cartTotalPrice } = shoppingCart;

  const cartTotalPrices = cartTotalPrice?.filter((cart) => cart.type === CART_TYPE.ONE_TIME);
  const oneTimePrice = cartTotalPrices?.find(
    (cart) => !cart.isNonImmediateOC && !cart?.isOnInvoiceOC && !cart?.isTotalPriceOC
  );
  let totalPrice = oneTimePrice?.price?.taxIncludedAmount;

  // retrieving the shippingCost and adding to the totalPrice if present
  if (includeShipping) {
    const shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
    if (shippingCost.price) totalPrice += shippingCost.price;
  }

  const totalPriceParsed = Number(totalPrice?.toString()).toFixed(2)?.replace('.', ',');
  return totalPriceParsed == '0,00' ? 0 : totalPriceParsed;
};

export const getFirstInvoicePrice = () => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const { cartTotalPrice } = shoppingCart;
  const firstInvoiceObject = cartTotalPrice?.find((cart) => cart.isOnInvoiceOC);
  const firstInvoicePrice = firstInvoiceObject?.price?.taxIncludedAmount;
  const totalPriceParsed = Number(firstInvoicePrice?.toString()).toFixed(2)?.replace('.', ',');
  return totalPriceParsed == '0,00' ? 0 : totalPriceParsed;
};

export const getOneTimeAmountFromShoppingCart = (): IShoppingCartCost => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const { cartTotalPrice } = shoppingCart;

  const cartTotalPrices = cartTotalPrice?.filter((cart) => cart.type === CART_TYPE.ONE_TIME);
  let oneTimePrice =
    cartTotalPrices?.length > 0 ? cartTotalPrices[cartTotalPrices.length - 1] : null;
  const considerIsNonImmediateOC = cartTotalPrices?.find((el) => el.isNonImmediateOC);
  if (considerIsNonImmediateOC) oneTimePrice = considerIsNonImmediateOC;

  const amount = oneTimePrice?.price?.taxIncludedAmount || 0;
  const orginalAmount = oneTimePrice?.originalprice?.taxIncludedAmount || 0;
  const amountParsed = Number(amount?.toString()).toFixed(2)?.replace('.', ',');
  const originalAmountParsed = Number(orginalAmount?.toString()).toFixed(2)?.replace('.', ',');
  return {
    formattedPrice: amountParsed == '0,00' ? 0 : amountParsed,
    price: amount,
    originalFormattedPrice: originalAmountParsed == '0,00' ? 0 : originalAmountParsed,
    originalPrice: orginalAmount,
  };
};

export const getCostFromShoppingCartByType = (type: string): IShoppingCartCost => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const { cartTotalPrice } = shoppingCart;
  const cartTotalPrices = cartTotalPrice?.filter((cart) => cart.type === type);
  const recurringPrice =
    cartTotalPrices?.length > 0 ? cartTotalPrices[cartTotalPrices.length - 1] : null;
  const amount = recurringPrice?.price?.taxIncludedAmount || 0;
  const orginalAmount = recurringPrice?.originalprice?.taxIncludedAmount || 0;
  const amountParsed = Number(amount?.toString()).toFixed(2)?.replace('.', ',');
  const originalAmountParsed = Number(orginalAmount?.toString()).toFixed(2)?.replace('.', ',');
  return {
    formattedPrice: amountParsed == '0,00' ? 0 : amountParsed,
    price: amount,
    originalFormattedPrice: originalAmountParsed == '0,00' ? 0 : originalAmountParsed,
    originalPrice: orginalAmount,
  };
};

export const getFixedPlanCostFromShoppingCart = () => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const plan = shoppingCart.cartItem?.[0]?.internet?.[0]?.plan;
  const amount =
    plan?.itemPrice?.find((it) => it.type === 'recurring')?.price?.taxIncludedAmount || 0;
  const amountParsed = Number(amount?.toString()).toFixed(2)?.replace('.', ',');
  const originalAmount =
    plan?.itemPrice?.find((it) => it.type === 'recurring')?.originalprice?.taxIncludedAmount || 0;
  const originalAmountParsed = Number(originalAmount?.toString()).toFixed(2)?.replace('.', ',');
  const recurringPeriod = plan?.itemPrice?.find((it) => it.type === 'recurring')?.discount?.[0]
    .duration;

  return {
    formattedPrice: amountParsed == '0,00' ? 0 : amountParsed,
    price: amount,
    originalFormattedPrice: originalAmountParsed == '0,00' ? 0 : originalAmountParsed,
    originalPrice: originalAmount,
    recurringPeriod,
  };
};

export const getMobilePlanCostFromShoppingCart = () => {
  const shoppingCart = retrieveShoppingCart();
  if (!shoppingCart) return;
  const plan = shoppingCart.cartItem?.[0]?.plan;
  const amount =
    plan?.itemPrice?.find((it) => it.type === 'recurring')?.price?.taxIncludedAmount || 0;
  const amountParsed = Number(amount?.toString()).toFixed(2)?.replace('.', ',');
  const originalAmount =
    plan?.itemPrice?.find((it) => it.type === 'recurring')?.originalprice?.taxIncludedAmount || 0;
  const originalAmountParsed = Number(originalAmount?.toString()).toFixed(2)?.replace('.', ',');
  const recurringPeriod = plan?.itemPrice?.find((it) => it.type === 'recurring')?.discount?.[0]
    .duration;

  return {
    formattedPrice: amountParsed == '0,00' ? 0 : amountParsed,
    price: amount,
    originalFormattedPrice: originalAmountParsed == '0,00' ? 0 : originalAmountParsed,
    originalPrice: originalAmount,
    recurringPeriod,
  };
};

export const retrieveLockInOfferFromStorage = (): ICampaignResponse => {
  const lockinOfferFoundSaved = localStorage.getItem('lockinOfferFound');
  const lockinOfferFound: ICampaignResponse =
    lockinOfferFoundSaved !== 'undefined' && JSON.parse(lockinOfferFoundSaved);
  LoggerInstance.debug('LockIn - retrieveAllProdutcs lockinOfferFound: ', lockinOfferFound);
  return lockinOfferFound;
};

export const isInABTest = (): boolean => {
  if (!checkWindow()) {
    return false;
  }
  const searchParams =
    window.location && window.location.href ? new URL(window.location.href).searchParams : '';
  return searchParams ? searchParams?.get(IN_TEST) === '1' || false : false;
};

export const getParamsInUrl = (key: string): string => {
  if (!checkWindow()) {
    return '';
  }
  const searchParams =
    window.location && window.location.href ? new URL(window.location.href).searchParams : '';
  return searchParams ? searchParams?.get(key) || '' : '';
};

export const isInApp = (): boolean => {
  if (!checkWindow()) {
    return false;
  }
  const searchParams =
    window.location && window.location.href ? new URL(window.location.href).searchParams : '';
  return searchParams ? searchParams?.get(IN_APP) === 'true' || false : false;
};

export const retrieveInTest = (): boolean => !!localStorage.getItem(IN_TEST);

export const retrieveInTestByOffer = (): boolean => {
  const product = retrieveProduct();
  return product?.enableAbTest && retrieveInTest();
};

export const isMobileProduct = (): boolean => {
  const product = getFromLocalStorageByKey('tProd');
  if (product?.action?.type)
    return (
      product.action?.type.toString() === IActionType.CHECKOUT_MODAL_MOBILE.toString() || false
    );
  return false;
};

export const getCurrentUserType = (): IUserType => {
  const silentLogin: ISilentLoginResponse = getFromLocalStorageByKey('silentLogin');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId);
  if (isLoggedUser) {
    return IUserType.LOGGED_USER;
  }
  if (findCaller?.resultInfo === 'existingNextCustomer' && findCaller?.customerRef?.id) {
    if (findCaller.customerHasActiveSubs) return IUserType.NEXT_USER_ACTIVE;
    return IUserType.NEXT_USER_INACTIVE;
  }
  return IUserType.PROSPECT_USER;
};

export const recognizedUser = () => {
  const findCallerSaved = localStorage.getItem('findCaller');
  const silentLoginSaved = localStorage.getItem('silentLogin');
  const silentLogin: ISilentLoginResponse =
    !!silentLoginSaved &&
    silentLoginSaved !== 'undefined' &&
    JSON.parse(silentLoginSaved as string);
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId);
  const findCaller: IFindCallerResponse =
    !!findCallerSaved && findCallerSaved !== 'undefined' && JSON.parse(findCallerSaved as string);
  return (
    isLoggedUser ||
    (findCaller?.customerHasActiveSubs &&
      findCaller?.resultInfo === 'existingNextCustomer' &&
      findCaller?.customerRef?.id)
  );
};

export const handleFirstCharacterOfSentence = (textToFormat: string) =>
  textToFormat
    ?.toLowerCase()
    ?.split(' ')
    ?.map((text: string) => text.charAt(0).toUpperCase() + text.substring(1))
    ?.join(' ');

/**
 * Check if user is R02
 */
export const isR02User = (
  coexistenceCookie: string | ((value: string, numberOfDays: number) => void)
): boolean => {
  if (coexistenceCookie) {
    const cookieVal =
      (coexistenceCookie && (coexistenceCookie as string)?.split('_')[2].trim()) || '';
    if (cookieVal === '01' || cookieVal === '02') {
      return true;
    }
  }
  const coexistence = localStorage.getItem('coexistenceUser');
  const coexistenceData =
    !!coexistence && coexistence !== 'undefined' && JSON.parse(coexistence as string);
  LoggerInstance.debug('R02', coexistenceData);
  if (coexistenceData) {
    return (
      coexistenceData?.trustCookie === 'yes' && coexistenceData?.userType === CUSTOMER_TYPES.LEGACY
    );
  }
  return false;
};

export const replaceShippingCost = (label, price = '') =>
  label?.replace(CART_TYPE.PLACEHOLDER_SHIPPING_PRICE, price) || '';

export const getCustomerIdentification = (
  owningIndividual: IOwningIndividual
): IIdentification | null => {
  const identifications = owningIndividual?.identification || [];
  if (!identifications || identifications.length === 0) return null;
  return identifications.length > 0
    ? identifications[identifications.length - 1]
    : identifications[0];
};

/**
 * Method to get the customer country
 * @param owningData
 */
export const getCustomerCountry = (owningData: IPersonalDataObject) => {
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const identification = getCustomerIdentification(owningData.owningIndividual);
  return (countries as ICountryObject[])?.filter(
    (countryObj: any) => identification?.nationality === countryObj.displayName
  );
};

/**
 * Method to get the customer document
 * @param owningData
 */
export const getCustomerDocument = (owningData: IPersonalDataObject) => {
  const customerReference = getFromLocalStorageByKey('customerReference');
  const identification = getCustomerIdentification(owningData.owningIndividual);
  return customerReference?.identificationType?.filter(
    (documentObj: any) => identification?.identificationType === documentObj.displayName
  );
};

/**
 * This method is used to check if the edited address from the frontend has all field filled.
 * @param address1 check if all fields are not empty
 * @param address2 check if we have some differences from the previous setted address
 * @returns boolean enabler for shipping or billing flow
 */
export const isDifferentAddress = (
  address1: IAddressDataObjectExtended,
  address2: IAddressDataObjectExtended
) =>
  address1?.city &&
  address1?.postalCode &&
  address1?.stateOrProvince &&
  address1?.street &&
  address1?.streetNumber &&
  (address1?.city.toUpperCase() !== (address2 as IAddressResponse)?.city?.toUpperCase() ||
    address1?.postalCode.toUpperCase() !==
      (address2 as IAddressResponse)?.postalCode?.toUpperCase() ||
    address1?.stateOrProvince.toUpperCase() !==
      (address2 as IAddressResponse)?.stateOrProvince?.toUpperCase() ||
    address1?.street.toUpperCase() !== (address2 as IAddressResponse)?.street?.toUpperCase() ||
    address1?.streetNumber.toUpperCase() !==
      (address2 as IAddressResponse)?.streetNumber?.toUpperCase());

/**
 * Method to check if is go back SIA
 */
export const checkIsGoBackSIA = (): boolean => {
  if (!checkWindow()) return false;
  const queryString = window?.location?.search;
  return (
    queryString.includes(ErrorCodes.ERROR_ON_PAYMENT) ||
    queryString.includes(ErrorCodes.MODE_BACK_SIA_INSTANT) ||
    queryString.includes(ErrorCodes.MODE_BACK_SIA_RECURRENT) ||
    queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT) ||
    queryString.includes(ErrorCodes.MODE_BACK_SIA) ||
    queryString.includes(ErrorCodes.MODE_DONE_SIA_INSTANT)
  );
};

export const checkIsApp = () => AppMobile.checkIsApp();

/**
 * Return true if is come from SIA
 */
export const checkBypassApiCallFromSIA = (): boolean => {
  if (!checkWindow()) return false;
  return !!localStorage.getItem(BYPASS_CALL_API_SIA);
};

/**
 * Method to get last payment method for paymean
 * @param payMean
 * @param type
 */
export const getLastPaymentMethod = (
  payMean: IPayMeanServiceResponse,
  type?: string
): IPayMean | undefined => {
  if (!payMean || !payMean?.payMeans) return;
  if (payMean?.payMeans?.length === 0) return;
  const lastSelectedType = localStorage.getItem(SELECTED_PAYMENT) || type || '';
  if (lastSelectedType) {
    const filteredPaymeans = payMean.payMeans?.filter(
      (el) => el.type.toLowerCase() === lastSelectedType.toLowerCase()
    );
    if (filteredPaymeans?.[0]) return filteredPaymeans[0];
    return payMean.payMeans[0];
  }
  // the last payment method is not the last but the first
  // return payMean.payMeans[payMean.payMeans.length - 1];
  return payMean.payMeans[0];
};

export const organizeCMSErrors = (errorsFromCms: ICMSApiError): ICheckOrderErrors => {
  const { errorcomponent } = errorsFromCms || {};
  if (!errorcomponent) {
    return {
      redirectToShoppingCartErrorOnPayment: [
        {
          error: 'hardko',
        },
        {
          error: 'softko',
        },
        {
          error: 'timeouthardko',
        },
        {
          error: 'timeoutSoftKo',
        },
        {
          error: 'paymeanalreadyexist',
        },
        {
          error: 'paymeanalreadyexistforsamebankcode',
        },
        {
          error: 'mandaterejectedorcancelled',
        },
        {
          error: 'ibanvalidationfailed',
        },
        {
          error: 'timeoutProcessing',
        },
      ],
      blockingErrors: [
        {
          error: 'ERROR_ON_PAYMENT_GENERIC',
          title: '',
          message: 'Si è verificato un errore, riprovare in seguito',
        },
        {
          error: ErrorCodes.AUTHORIZATION_ERROR_KO,
          title: '',
          message: 'Si è verificato un errore, riprovare in seguito',
        },
        {
          error: ErrorCodes.PAYMENT_DISTRIBUTION_ERROR,
          title: '',
          message: 'Si è verificato un errore, riprovare in seguito',
        },
        {
          error: ErrorCodes.RESERVE_EQUIPMENT_ERROR,
          title: '',
          message: 'Si è verificato un errore, riprovare in seguito',
        },
        {
          error: ErrorCodes.MODIFY_PHONE_NUMBER_PRODUCT_ERROR,
          title: '',
          message: 'Si è verificato un errore, riprovare in seguito',
        },
      ],
    };
  }
  const mapErrors = (errors: ICMSError[]) =>
    errors?.map((error) => ({
      ...error,
      isBlocking: error?.isClickToCall || 'false',
      title: error?.title || '',
      message: error?.message || '',
    }));
  return {
    redirectToShoppingCartErrorOnPayment: mapErrors(
      errorcomponent?.errors?.filter(
        (error) => !error.isClickToCall || error?.isClickToCall === 'false'
      )
    ),
    blockingErrors: mapErrors(
      errorcomponent?.errors?.filter((error) => error?.isClickToCall === 'true')
    ),
  };
};

/**
 * Method to return the blocking and the back's errors
 * @param orderErrors
 */
export const getBackAndBlockinErrors = (orderErrors: ICMSApiError) => {
  const { redirectToShoppingCartErrorOnPayment, blockingErrors } = organizeCMSErrors(orderErrors);
  const goBackErrorOnPayment = redirectToShoppingCartErrorOnPayment.map((errorLocal) =>
    errorLocal?.error?.toLowerCase()
  );
  const blockErrors = blockingErrors.map((errorLocal) => errorLocal?.error?.toLowerCase());
  return {
    goBackErrorOnPayment: redirectToShoppingCartErrorOnPayment,
    goBackErrorOnPaymentFormattedErrors: goBackErrorOnPayment,
    blockingFormattedError: blockErrors,
    blockingErrors,
  };
};

export const getCompleteAddressValidation = (coverageToolConfig: ICoverageToolCongifg) =>
  yup
    .object({
      placeId: yup.string().required(''), //  This is handled in AddressInput
      city: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || ' ')
        .matches(
          /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ'"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' '
        ),
      //  .required(coverageToolConfig.coveragetool.errorMessage.error0),
      street: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || ' ')
        .matches(
          /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' '
        ),
      // .required(coverageToolConfig.coveragetool.errorMessage.error0),
      streetNumber: yup
        .string()
        .test(
          'rangeTest',
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' ',
          (value) => {
            if (value?.match(/^[1-9][0-9]{0,4}(?:\/)?[a-zA-Z]?$/)) {
              return true;
            }
            return false;
          }
        )
        .required(coverageToolConfig?.coveragetool?.errorMessage?.error0),
    })
    .required();

export const updateContactProblemDataLocal = (data: { checkContactProblem: boolean }) => {
  if (data?.checkContactProblem) localStorage.setItem(CONTACT_ME, '1');
  else localStorage.removeItem(CONTACT_ME);
};

/**
 * Method to return the Address info by placeId
 * @param placeId
 */
export const getAddressInfoByPlaceId = async (
  placeId
): Promise<{
  placeDetails: IAddressResponse;
  addressString: string;
}> => {
  const placeDetails = await getPlaceId(placeId);
  const picked = (({ city, postalCode, stateOrProvince, street, streetNumber }) => ({
    city,
    postalCode,
    stateOrProvince,
    street,
    streetNumber,
  }))(placeDetails);
  let addressString = '';
  Object.values(picked).map((el: string) => {
    if (el) addressString = addressString.concat(el, ', ');
    return null;
  });
  return {
    placeDetails: {
      ...placeDetails,
      latitude: placeDetails?.latitude ? parseFloat(placeDetails?.latitude) : 0,
      longitude: placeDetails?.longitude ? parseFloat(placeDetails?.longitude) : 0,
    },
    addressString,
  };
};

/**
 * Method to return the hubPath
 * @param pageProduct
 */
export const getHubPath = (pageProduct: IProduct) => {
  const storageHubPath = localStorage?.getItem('hubPath') || '';
  const hubPath = storageHubPath || pageProduct?.hubPath || '';
  if (!hubPath) return '';
  const path = !hubPath?.includes('http') ? `${getHome()}/${hubPath}` : hubPath;
  const product = retrieveProduct();
  const isWinbackProduct = product?.isWinback;
  if (isWinbackProduct) {
    const cC = product?.cc;
    const tC = product?.tc;
    if (cC && tC) return `${path}?cC=${cC}&tC=${tC}`;
    return `${path}?cC=${cC}`;
  }
  return path || '';
};

export const setHubPath = (slug: string): string => {
  const href = window?.location?.href || '';
  const url = new URL(href);
  url.search = '';
  if (!slug || !href || !url.toString()) return '';
  return url.toString();
};

/**
 * Method to redirect to product hub
 */
export const goToHubProduct = () => {
  let url = getHome();
  const product = retrieveProduct();
  if (product?.hubPath) {
    if (product.hubPath.includes('http')) url = product.hubPath;
    else url += `/${product.hubPath}`;
  }
  window.location.href = `${url}`;
};

export const setCartCookie = (
  product: IProduct,
  addressCoverage?: IAddressResponse,
  technology?: ITechInfo,
  numberOfDays?: number,
  userType?: string
) => {
  if (!checkWindow()) return;
  const cmsId = product?.cmsId || '';
  const initialCmsId = localStorage.getItem('cms_tool_start');
  const keepInitialCms: boolean = initialCmsId && initialCmsId !== '' && initialCmsId !== cmsId;
  const hubPath = localStorage.getItem('hubPath');
  setItemCookie(
    COOKIE_RETRIEVE_CART,
    {
      offerId: product?.offerId || '',
      cmsId: keepInitialCms ? initialCmsId : cmsId,
      productId: cmsId,
      address: addressCoverage,
      placeId: localStorage.getItem('placeId') || '',
      offerDetails: {
        redirectUrl: getRedirectCoverage() || '',
        isLockinProduct: product?.isLockInProduct,
      },
      hubPath,
      technology,
      userType,
    },
    numberOfDays || 2,
    false,
    'privati.vodafone.it'
  );
};

export const retrieveCartCookie = () => {
  if (!checkWindow()) return;
  const cartCookie = getItemCookie(COOKIE_RETRIEVE_CART);
  if (!cartCookie) return;
  return cartCookie;
};

export const deleteCartCookie = () => setItemCookie(COOKIE_RETRIEVE_CART, '', 0);

/**
 * This method is used for delete all cookies
 * add necessary cookies
 */
export const deleteAllCookies = (ignoreCookies?: string[]) => {
  const cookiesKeys = [PARTNER_OFFER_COOKIE, COOKIE_CART, LOCKIN_OFFER_COOKIE];
  cookiesKeys.forEach((el) => {
    if (!ignoreCookies || !ignoreCookies?.includes(el)) setItemCookie(el, '', 0);
  });
};

/**
 * Get Dxl price by dxl nbo campaign
 * @param dxlNboCampaign
 */
export const getDXLProductPrice = (dxlNboCampaign: IDXLNboProduct): string => {
  const dxlNboCampaignPrice = dxlNboCampaign?.cmsCustomization?.renewalCost?.price;
  let price = dxlNboCampaignPrice?.toString().replace(/\s/g, '') || '';
  LoggerInstance.debug('DXL NBO PRICE', price);
  if (!price) {
    return '';
  }
  if (price.includes('.')) {
    const splittedPrice = dxlNboCampaignPrice?.toString()?.split('€');
    if (splittedPrice?.[0])
      price = `${parseFloat(splittedPrice[0])?.toFixed(2).replace('.', ',')}€`;
  }
  return price;
};
/**
 * Get Dxl price by dxl addon price
 * @param price
 */
export const getDXLAddonProductPrice = (price: string): number => {
  let priceToReturn = 0;
  const updatedPrice = price?.toString().replace(/\s/g, '') || '';
  if (!updatedPrice) {
    return priceToReturn;
  }
  if (updatedPrice.includes('.')) {
    const splittedPrice = price?.toString()?.split('€');
    if (splittedPrice?.[0]) priceToReturn = parseFloat(parseFloat(splittedPrice[0])?.toFixed(2));
  }
  return priceToReturn;
};
/**
 * Get Dxl BundlePO
 */
export const getDXLBundlePO = (): string => {
  const dxlNboCampaign: IDXLNboProduct = getFromLocalStorageByKey(DXL_NBO_CAMPAIGN);
  LoggerInstance.debug('DXL NBO', dxlNboCampaign);
  return dxlNboCampaign?.bundlePO || '';
};

export const getProductCategory = (offerType: string) => {
  switch (offerType) {
    case '2':
    case 'fixed':
      return 'fixed';
    case '3':
    case 'mobile':
      return 'mobile';
    case '8':
    case 'adv':
      return 'adv';
    default:
      return '';
  }
};

export const pushWidgets = (
  widgetType: IWidgetType,
  productSliders: ICarouselCMS[],
  globalPromoConfig?: IGlobalPromo
) => {
  const sliders = cloneDeep(productSliders);
  if (sliders.find((slider) => slider.type === widgetType) && globalPromoConfig?.slides) {
    sliders
      .find((slider) => slider.type === widgetType)
      .elements.unshift(...globalPromoConfig.slides);
  }
  return sliders;
};

export const checkPanicMode = (panicMode?: IPanicMode, product?: IProduct): boolean => {
  if (product?.action?.type?.toString() === IActionType.CHECKOUT_MODAL_MOBILE.toString())
    return panicMode?.enablePanicModeMobile === 'true' || false;
  return panicMode?.enablePanicModeFixed === 'true' || false;
};

export const overridePromo = (product?: IProduct, globalPromoConfig?: IGlobalPromo): IProduct => {
  if (product?.isGlobalPromoActive && !product?.isAlreadyGlobalPromoOverWritten) {
    return {
      ...product,
      isAlreadyGlobalPromoOverWritten: true,
      inflactionHub: [
        {
          disclaimer: globalPromoConfig?.cartDisclaimer || '',
          text: globalPromoConfig?.label,
          popup: globalPromoConfig?.popupDescription,
          color: globalPromoConfig?.color,
        },
        ...(product?.inflactionHub || []),
      ],
      inflactionPdp: [
        {
          disclaimer: globalPromoConfig?.cartDisclaimer || '',
          text: globalPromoConfig?.label,
          popup: globalPromoConfig?.popupDescription,
          color: globalPromoConfig?.color,
        },
        ...(product?.inflactionPdp || []),
      ],
      inflactionCartView: [
        {
          text: globalPromoConfig?.label,
          popup: globalPromoConfig?.popupDescription,
          color: globalPromoConfig?.color,
          disclaimer: globalPromoConfig?.cartDisclaimer || '',
        } as IInflaction,
      ].concat(product?.inflactionCartView),
      ...(globalPromoConfig?.characteristcs && {
        offerDetails: product?.offerDetails?.concat(globalPromoConfig.characteristcs),
      }),
      ...(globalPromoConfig?.detailPageDisclaimer &&
        product?.pdpDisclaimer !== globalPromoConfig.detailPageDisclaimer && {
          pdpDisclaimer: globalPromoConfig.detailPageDisclaimer.concat(product?.pdpDisclaimer),
        }),
    };
  }

  return product;
};

export const overrideAction = (product?: IProduct, overrideFlag?: boolean) => {
  const getDefaultAction = (): IActionSlide => {
    const isHomePage = window?.location?.pathname === '/';
    if (isHomePage) {
      return {
        title: product?.homepageDetail?.action?.title || product?.action?.title || '',
        type: product?.homepageDetail?.action?.type ? +product?.homepageDetail?.action?.type : -1,
        url: product?.homepageDetail?.action?.url || '',
        callMeNow:
          product?.homepageDetail?.action?.callMeNow || product?.homepageDetail?.action?.url || '',
        localUrl: product?.homepageDetail?.action?.localUrl || '',
      };
    }
    return {
      title: product?.action?.title || '',
      type: product?.action?.type ? +product?.action?.type : -1,
      url: product?.action?.url || '',
      callMeNow: product?.action?.callMeNow || product?.action?.url || '',
      localUrl: product?.action?.localUrl || '',
    };
  };

  let actionProduct = getDefaultAction();

  if (overrideFlag) {
    actionProduct = {
      title: product?.panicModeAction?.title || '',
      type: product?.panicModeAction?.type
        ? +product?.panicModeAction?.type
        : IActionType.CALL_ME_NOW,
      url: product?.panicModeAction?.url || '',
      callMeNow: product?.panicModeAction?.url || '',
      localUrl: product?.panicModeAction?.url || '',
    };
  }

  return actionProduct;
};

export const overrideProductList = (
  products: IProduct[],
  panicModeConfiguration?: IPanicMode,
  globalPromoConfig?: IGlobalPromo
): IProduct[] =>
  products.map((product) => ({
    ...overridePromo(product, globalPromoConfig),
    ...(checkPanicMode(panicModeConfiguration, product) && { hubSecondAction: undefined }),
    action: { ...overrideAction(product, checkPanicMode(panicModeConfiguration, product)) },
  }));

export const getFwToken = () => localStorage.getItem(TPO_TKN) || '';

export const checkFwAmdIntegration = () => {
  const isEnableTokenFwAmd =
    process.env?.['NEXT_PUBLIC_FW_AMD_INTEGRATION'] === 'TRUE' ||
    process.env?.['NX_PUBLIC_FW_AMD_INTEGRATION'] === 'TRUE';
  if (!isEnableTokenFwAmd) return '';
  return getFwToken();
};
