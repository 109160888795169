import { iFrameManager } from '@vfit/shared/data-access';

export const openPopup = (
  url: string,
  onClose?: () => void,
  fullscreen?: boolean,
  fullScreenExtended?: boolean,
  icon?: string,
  showIcon?: boolean,
  showLogo?: boolean,
  title?: string,
  variant?: 'mva' | 'sunrise',
  isModalFullContainer?: boolean,
  tabletMode?: boolean,
  minHeight?: number,
) => {
  iFrameManager.handleIFrame({
    url,
    onClose,
    fullscreen,
    fullScreenExtended,
    icon,
    showIcon,
    showLogo,
    title,
    variant,
    isModalFullContainer,
    tabletMode,
    minHeight
  });
};
