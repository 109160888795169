import * as C from './components';

export const svgObj = {
  account: C.Account,
  arrowDown: C.ArrowDown,
  arrowLeft: C.ArrowLeft,
  arrowRight: C.ArrowRight,
  calendar: C.Calendar,
  call: C.Call,
  callLog: C.CallLog,
  camera: C.Camera,
  celebrate: C.Celebrate,
  chevronDown: C.ChevronDown,
  chevronDownBtn: C.ChevronDownBtn,
  chevronLeft: C.ChevronLeft,
  chevronRight: C.ChevronRight,
  chevronRightBtn: C.ChevronRightBtn,
  chevronUp: C.ChevronUp,
  circleStatusError: C.CircleStatusError,
  circleStatusInfo: C.CircleStatusInfo,
  circleStatusSuccess: C.CircleStatusSuccess,
  close: C.Close,
  closeBtn: C.CloseBtn,
  closeLs: C.CloseLs,
  copy: C.Copy,
  dotLs: C.DotLs,
  eye: C.Eye,
  eyeSlash: C.EyeSlash,
  error: C.Error,
  leaf: C.Leaf,
  locateMe: C.LocateMe,
  location: C.Location,
  magnifyingGlassPlus: C.MagnifyingGlassPlus,
  magnifyingGlassMinus: C.MagnifyingGlassMinus,
  menu: C.Menu,
  minus: C.Minus,
  myTrips: C.MyTrips,
  payments: C.Payments,
  pen: C.Pen,
  plus: C.Plus,
  plusBtn: C.PlusBtn,
  search: C.Search,
  success: C.Success,
  speedTest: C.SpeedTest,
  spinner: C.Spinner,
  thumbUp: C.ThumbUp,
  thumbDown: C.ThumbDown,
  tick: C.Tick,
  tickCircle: C.TickCircle,
  tickCircleCyan: C.TickCircleCyan,
  tickLs: C.TickLs,
  trashCan: C.TrashCan,
  warning: C.Warning,
  warningBig: C.WarningBig,
  warningDot: C.WarningDot,
  infoAndHelp: C.InfoAndHelp,
  download: C.Download,
  wifi: C.Wifi,
  arrowRightFw: C.ArrowRightFw,
  info: C.Info
};
