import {ISvgComponent} from "../../svg.models";
import * as S from "../svg.style";

export const Info : React.FC<ISvgComponent> = ({color, height, width, shrinkable, ...rest}) => (
  <S.Svg xmlns="http://www.w3.org/2000/svg"
         width={width}
         height={height}
         color={color}
         viewBox="0 0 16 16"
         fill="none"
         shrinkable={shrinkable}
         {...rest}
  >

    <path
      d="M8.00001 13.4186V5.72729H6.66667M6.66667 13.6667H9.33334M7.83334 1.92301C7.83334 1.83166 7.75872 1.75761 7.66667 1.75761C7.57462 1.75761 7.50001 1.83166 7.50001 1.92301M7.99997 1.93131C7.99997 2.07747 7.88058 2.19596 7.73331 2.19596C7.58603 2.19596 7.46664 2.07747 7.46664 1.93131C7.46664 1.78515 7.58603 1.66667 7.73331 1.66667C7.88058 1.66667 7.99997 1.78515 7.99997 1.93131Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />


  </S.Svg>
)
